import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Giving Back – Dental Mission Haiti | The Smile Stylist" 
    description="Find out how Dr. Kaloti, Canada’s leading expert in cosmetic dentistry goes beyond our nation’s borders to help people and communities in need thrive." 
    keywords="mission haiti, haiti, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="giving-back hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Giving Back</h1>
            <h2>Mission Haiti</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>Dental Mission Haiti</h2>
            <p>Just got back from my week long trip in Haiti. It's true, the destruction is beyond compare. As soon as you step foot off the plane you are ushered into a little make-shift corridor that replaces the old airport terminal that was completely rendered unsafe and for demolition by the earthquakes last year. The irony is as that at the end of that long and narrow corridor you are greeted by a festive and an almost obnoxiously loud musical trio with their warm welcoming smiles, bright colors, drums and guitars... a true testament to the resilience and courage of the Haitian people!</p>
            <p>As we left the airport, it was evident that the destruction at the airport was only the beginning. With 1.5 million people left homeless to date, there is much work yet to be done... but we tried to stay focused on our mission... improve oral health for as many kids as possible! We came armed with 4 luggage bags full of dental kits, educational material, toys... and we succeeded!</p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.4s">
            <a className="fancybox-buttons" data-fancybox-group="button" href="/inc/images/haiti/haiti-05.jpg" title=""><img src={ require("../images/desktop/haiti/haiti-05.jpg")} alt="" className="img-thumbnail" /></a>
          </div>
        </div>
        <div className="row">
          <hr />
          <div className="col-xs-6 col-sm-4 col-md-3">
            <a className="fancybox-buttons" data-fancybox-group="button" href="/inc/images/haiti/haiti-01.jpg" title=""><img src={ require("../images/desktop/haiti/haiti-01.jpg")} alt="" className="img-thumbnail" /></a>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3">
            <a className="fancybox-buttons" data-fancybox-group="button" href="/inc/images/haiti/haiti-02.jpg" title=""><img src={ require("../images/desktop/haiti/haiti-02.jpg")} alt="" className="img-thumbnail" /></a>
          </div>
          <div className="clearfix visible-xs"></div>
          <div className="col-xs-6 col-sm-4 col-md-3">
            <a className="fancybox-buttons" data-fancybox-group="button" href="/inc/images/haiti/haiti-03.jpg" title=""><img src={ require("../images/desktop/haiti/haiti-03.jpg")} alt="" className="img-thumbnail" /></a>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3">
            <a className="fancybox-buttons" data-fancybox-group="button" href="/inc/images/haiti/haiti-04.jpg" title=""><img src={ require("../images/desktop/haiti/haiti-04.jpg")} alt="" className="img-thumbnail" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage